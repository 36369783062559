// File: src/js/helpers.js (Frontend specific)
function safeParseJSON(json, fallback = null) {
  try {
    return JSON.parse(json);
  } catch (error) {
    console.error('Error parsing JSON:', error, 'Input:', json);
    return fallback;
  }
}

function pj(json) {
  return JSON.stringify(json, null, 2);
}

function clj(json, msg = 'json') {
  console.log(msg, pj(json));
}

function myDate() {
  const date = new Date();
  return `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`;
}

function isEmpty(val) {
  if (val instanceof Map) {
    return val.size === 0;
  }
  const isEmpty =
    val === undefined ||
    val == null ||
    val == '' ||
    !(Object.keys(val) || val).length;
  return isEmpty;
}
const getTopicOrderKey = (spaceId) => {
  if (!spaceId) {
    throw new Error('Space ID is required to generate the topic order key.');
  }
  return `space:${spaceId}:topicOrder`;
};


module.exports = {
  safeParseJSON,
  pj,
  clj,
  myDate,
  isEmpty,
  getTopicOrderKey,
};
