const CryptoJS = require('crypto-js');
const { safeParseJSON } = require('./helpers');

const SECRET_KEY = 'your-secret-key'; // Replace with a secure key

const localStorageService = {
  setItem(key, value, sensitive = false) {
    try {
      const data = sensitive
        ? CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString()
        : JSON.stringify(value);

      localStorage.setItem(key, data);
    } catch (error) {
      console.error(`Error storing key "${key}" in localStorage:`, error);
    }
  },

  getItem(key, sensitive = false) {
    try {
      const data = localStorage.getItem(key);
      if (!data) return null;

      return sensitive
        ? safeParseJSON(
            CryptoJS.AES.decrypt(data, SECRET_KEY).toString(CryptoJS.enc.Utf8)
          )
        : safeParseJSON(data);
    } catch (error) {
      console.error(
        `Error retrieving or parsing key "${key}" from localStorage:`,
        error
      );
      return null;
    }
  },

  removeItem(key) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing key "${key}" from localStorage:`, error);
    }
  },

  clear() {
    try {
      localStorage.clear();
    } catch (error) {
      console.error(`Error clearing localStorage:`, error);
    }
  },

  get length() {
    return localStorage.length;
  },

  key(index) {
    return localStorage.key(index);
  },
};
export default localStorageService;
