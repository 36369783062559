window.onerror = function (message, source, lineno, colno, error) {
  // `message` is the error message
  // `source` is the source URL of the script that caused the error
  // `lineno` is the line number where the error occurred
  // `colno` is the column number where the error occurred
  // `error` is an Error object containing more details about the error

  console.error('Global error handler:', error);
  console.error('Message:', message);
  console.error('Source:', source);
  console.error('Line number:', lineno);
  console.error('Column number:', colno);
};
// BASE imports
import Vue from 'vue';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import vuetify from './plugins/vuetify';
import localStorageService from './js/localStorageService';

import './registerServiceWorker';

import VueSocketIOExt from 'vue-socket.io-extended';
const lctJsonOptions = require('../srv/redis/config/redisJson.options');

// Brand icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);

Vue.config.errorHandler = function (err, vm, info) {
  // `err` is the error object
  // `vm` is the Vue instance where the error occurred
  // `info` is a string containing additional information about the error

  // You can log the error, send it to a logging service, or perform any other custom handling
  console.error('Vue error handler:', err);
  console.error('Component where error occurred:', vm);
  console.error('Additional info:', info);
};

//i18n
import i18n from './plugins/i18n';

// TODO FIX: need a more flexible way to control primary key (pk)
const pk = localStorageService.getItem('pk');
console.warn('pk', pk);
Vue.prototype.$pk = pk ?? '';

Vue.prototype.$reload = false;

const pin = localStorageService.getItem('pin');
console.warn('pin', pin);
Vue.prototype.$pin = pin ?? '';

import packageJson from '../package.json';
const { version, experimenting, countryOverride, da } = packageJson;

function getCountry() {
  console.warn('includes "-" ?', hostName.includes('-'));
  return hostName.includes('-') ? 'yy' : hostName.slice(0, 2);
}

Vue.prototype.$version = version;

Vue.prototype.$experimenting = experimenting;

const hostName = window.location.hostname;
const pathname = window.location.pathname;
console.warn('hostName:', hostName);
console.warn('hostName:', pathname);
console.warn('countryOverride:', countryOverride);

const country = countryOverride ? countryOverride : getCountry();
// const country = countryOverride ? `${countryOverride}:` : `${getCountry()}`;
console.warn('country:', country);

Vue.prototype.$country = country;
Vue.prototype.$blankComponent = 3;
Vue.prototype.$delim = '#';
Vue.prototype.$capturedError = '';

const auth = { userID: pk, pin };
console.warn(auth, JSON.stringify(auth, null, 2));

// const debug=  da ?? (country === 'xx' || country === 'lo');
const debug = da ?? country === '19';
console.warn('debug: ', debug);
Vue.prototype.$debug = debug;

console.warn('prod :>> ', process.env.NODE_ENV != 'production');
Vue.prototype.$testing = process.env.experimenting;
Vue.prototype.$secret =
  process.env.SECRETHANDSHAKE ?? lctJsonOptions.SECRETHANDSHAKE;

import initializeSocket from './services/socketService';

const socket = initializeSocket(auth);
Vue.use(VueSocketIOExt, socket);

Vue.prototype.$preambleBase = `tqr:${country}`;

Vue.prototype.$myError = function (name, message, info) {
  const error = Object.create(Error.prototype);
  error.message = message;
  error.info = info;
  error.name = name;
  return error;
};

function loadAppState() {
  const appState = localStorageService.getItem('appState');
  if (appState) {
    console.log('App state loaded:', appState);
  } else {
    localStorageService.setItem('appState', { initialized: true });
  }
}

Vue.config.devtools = true;

const pk2 = localStorageService.getItem('pk');
console.warn('pk2', pk2);

const isSyntopix = true //process.env.VUE_APP_CONTEXT === 'syntopix';
const getRouterAndApp = async () => {
  // Determine context at runtime

  // Use dynamic imports for the router and App
  const router = isSyntopix
  ? (await import('./router/syntopixRouter')).default
  : (await import('./router/tensorRouter')).default;

  const App = isSyntopix
  ? (await import('./syntopix/App.vue')).default
  : (await import('./App.vue')).default;


  return { App, router };
};
(async () => {
  const { router, App } = await getRouterAndApp();
  console.log('\nLoaded router:', router); // Log the router
  console.log('Loaded App:', isSyntopix ? 'Syntopix' : 'Tensor', '\n'); // Log the App context

  Vue.config.productionTip = false;
  new Vue({
    vuetify,
    router,
    i18n,
    render: (h) => h(App),
    mounted() {
      loadAppState();
    },
  }).$mount('#app');
})();
